import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; 
import CardLoading from "../../../../components/CardLoading";
import RatifyDocModal from "./modal/RatifyDocModal";
import svg11 from "../../../../assets/svg/svg11.svg";
import svg12 from "../../../../assets/svg/svg12.svg";
import infoSvg from "../../../../assets/svg/Info.svg";
import RatifyEmailModal from "./modal/RatifyEmailModal";
import { formatDateAndTime } from "../../../../utils";

export default function KybAlgoTable(receivedData) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, ratifyCount, ratifyData } = useSelector(
    (state) => state.fetch
  );

  const { activeOrganization } = useSelector((state) => state.obj);
  const [prodd, setProdd] = useState();

  const [open, setopen] = useState(false);
  function handleCancel() {
    setopen(false);
  }
  async function handleOpen(item) {
    await setProdd(item);
    await setopen(true);
  }

  const [openEmail, setopenEmail] = useState(false);
  function handleCancelEmail() {
    setopenEmail(false);
  }

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Checked",
      dataIndex: "checked",
      key: "checked",
      render: (value) =>
        value ? <img src={svg11} alt="Yes" /> : <img src={svg12} alt="12" />,
    },
    {
      title: "Passed",
      dataIndex: "passed",
      key: "passed",
      render: (value) =>
        value ? <img src={svg11} alt="Yes" /> : <img src={svg12} alt="12" />,
    },

    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Documents",
      dataIndex: "avps",
      render: (item) => {
        return (
          <div>
            <img src={infoSvg} alt="infoSvg" onClick={() => handleOpen(item)} />
          </div>
        );
      },
      key: "avps",
    },
    {
      title: "Last Run",
      dataIndex: "lastRun",
      render: (item) => {
        return <div>{formatDateAndTime(item)}</div>;
      },
      key: "lastRun",
    },
  ];

  const formattedData = Object.entries(receivedData?.data)?.map(
    ([key, value]) => ({
      key,
      checked: value?.checked,
      passed: value?.passed,
      pending: value?.pending,
      score: value?.score,
      lastRun: value?.lastRun,
      avps: value?.avps,
    })
  );

  if (loading) return <CardLoading />;

  return (
    <div className="">
      <div className="bg-white rounded-[10px] h-full">
        <div className="flex flex-col w-full  ">
          <div className="dash-inner-page   max-w-full w-full overflow-x-auto">
            <section>
              <Table
                className=" w-full"
                scroll={{
                  x: 1200,
                }}
                columns={columns}
                dataSource={formattedData}
                pagination={false}
                rowKey="key"
              />
            </section>
          </div>
        </div>
      </div>

      <RatifyDocModal open={open} handleCancel={handleCancel} prodd={prodd} />

      <RatifyEmailModal
        open={openEmail}
        handleCancel={handleCancelEmail}
        prodd={prodd}
      />
    </div>
  );
}
