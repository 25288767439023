import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadFiles } from "../../../../../features/global/globalSlice";
import toast from "react-hot-toast";
import { formatPathFile } from "../../../../../utils";

const { TextArea } = Input;

const dataSource = [
  {
    key: "1",
    name: "Mike",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: "2",
    name: "John",
    age: 42,
    address: "10 Downing Street",
  },
];

const RatifyDocModal = ({ open, handleCancel, handleFetchData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const handleClick = async (fileUrl,type) => { 
    const res = await dispatch(
      downloadFiles({
        attrType: type,
        fileName: fileUrl,
      })
    );

    if (res?.payload) {
      const blob = new Blob([res.payload], {
        type: res.payload.type || "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", formatPathFile(fileUrl));
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      toast.success("File downloaded successfully");
    } else {
      toast.error("Failed to download file");
    }
  };

  const columns = [
    {
      title: "Document ID",
      dataIndex: "val",
      key: "val",
    },
    {
      title: "Document Type",
      dataIndex: "attAttrType",
      key: "attAttrType",
    },
    {
      title: "Customer ID",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "Document url", 
      render:(item)=>{
        return(
          <div className="cursor-pointer text-[#4169E1] underline" onClick={() => handleClick(item?.attUrl,item?.attAttrType)}>{item?.attUrl}</div>
        )
      }, 
      key: "attUrl",
    },
  ];

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Documents`}
        open={open}
        // onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Table
          pagination={{
            position: ["bottomCenter"], 
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          dataSource={prodd}
          columns={columns}
        />
        
      </Modal>
    </>
  );
};
export default RatifyDocModal;
