import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import RatifyTable from "./RatifyTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchRatify } from "../../../../features/fetch/fetchSlice";
import svg67 from "../../../../assets/svg/svg67.svg";
import { formatDateAndTime } from "../../../../utils";
import CardLoading from "../../../../components/CardLoading";
import EmtyCard from "../../../../components/EmtyCard";

const CollapseRatify = () => {
  const dispatch = useDispatch();
  const { activeOrganization } = useSelector((state) => state.obj);
  const { refetchKey } = useSelector((state) => state.global);
  const { loading, ratifyCount, ratifyData } = useSelector(
    (state) => state.fetch
  );

  // const renderPanels = () =>
  //   ratifyData?.map((entry, index) => {
  //     const lastRun =
  //       formatDateAndTime(entry?.companyNumberMatchesCertOfIncorp?.lastRun) ||
  //       "N/A";
  //     return {
  //       key: `${index + 1}`,
  //       label: `Last Run: ${lastRun}`,
  //       children: <RatifyTable data={entry} />,
  //     };
  //   });
  const renderPanels = () => {
    return ratifyData?.map((entry, index) => { 
      const allLastRuns = Object.entries(entry)
        ?.map(([key, value]) => value?.lastRun) 
        ?.filter((lastRun) => lastRun); // Filter out undefined or null `lastRun`
  
      // Group or pick the most recent `lastRun`
      const mostRecentLastRun = allLastRuns?.length
        ? new Date(Math?.max(...allLastRuns?.map((date) => new Date(date)?.getTime())))
        : null;
  
      const lastRun = mostRecentLastRun
        ? formatDateAndTime(mostRecentLastRun)
        : "N/A";
  
      return {
        key: `${index + 1}`,
        label: `Last Run: ${lastRun}`,
        children: <RatifyTable data={entry} />,
      };
    });
  };
  

  async function fetchRatifyData() {
    await dispatch(
      fetchRatify({
        url: "api/v1/admin/ratify-dtb-organisation",
        orgUid: activeOrganization?.orgUid,
      })
    );
  }
  useEffect(() => {
    if (refetchKey == 4) {
      fetchRatifyData();
    }
  }, [refetchKey]);
  if (loading) return <CardLoading />;
  return (
    <>
      {ratifyData && ratifyData?.length > 0 ? (
        <>
          {" "}
          <div className="flex items-center justify-between py-[1.25rem]">
            <h3 className="heading_4 flex justify-start">
              Ratify Organizations{" "}
            </h3>

            <div
              className="cursor-pointer justify-center"
              onClick={() => fetchRatifyData()}
            >
              <img src={svg67} alt="svg67" />
            </div>
            <div></div>
          </div>
          <Collapse
            items={renderPanels()}
            defaultActiveKey={["1"]}
            onChange={(key) => console.log("Expanded panel:", key)}
          />
        </>
      ) : (
        <EmtyCard title="First send organization to DTB to do ratification" />
      )}
    </>
  );
};

export default CollapseRatify;
