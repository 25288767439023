import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchKybAlgo,
  fetchRatify,
} from "../../../../features/fetch/fetchSlice";
import svg67 from "../../../../assets/svg/svg67.svg";
import { formatDateAndTime } from "../../../../utils";
import CardLoading from "../../../../components/CardLoading";
import KybAlgoTable from "./KybAlgoTable";

const CollapseKYB = () => {
  const dispatch = useDispatch();
  const { activeOrganization } = useSelector((state) => state.obj);
  const { refetchKey } = useSelector((state) => state.global);
  const { loading, ratifyCount, ratifyData } = useSelector(
    (state) => state.fetch
  );
  const renderPanels = () => {
    return ratifyData?.map((entry, index) => {
      const allLastRuns = Object.entries(entry)
        ?.map(([key, value]) => value?.lastRun)
        ?.filter((lastRun) => lastRun); // Filter out undefined or null `lastRun`

      // Group or pick the most recent `lastRun`
      const mostRecentLastRun = allLastRuns?.length
        ? new Date(
            Math?.max(...allLastRuns?.map((date) => new Date(date)?.getTime()))
          )
        : null;

      const lastRun = mostRecentLastRun
        ? formatDateAndTime(mostRecentLastRun)
        : "N/A";

      return {
        key: `${index + 1}`,
        label: `Last Run: ${lastRun}`,
        children: <KybAlgoTable data={entry} />,
      };
    });
  };
  // const renderPanels = () =>
  //   ratifyData?.map((entry, index) => {
  //     const lastRun =
  //       formatDateAndTime(entry?.companyNumberMatchesCertOfIncorp?.lastRun) ||
  //       "N/A";
  //     return {
  //       key: `${index + 1}`,
  //       label: `Last Run: ${lastRun}`,
  //       children: <KybAlgoTable data={entry} />,
  //     };
  //   });

  async function fetchKybAlgoData() {
    await dispatch(
      fetchKybAlgo({
        url: "api/v1/admin/run-dtb-organisation-kyb-algo",
        orgUid: activeOrganization?.orgUid,
        type: "NORMAL",
      })
    );
  }
  useEffect(() => {
    if (refetchKey == 5) {
      fetchKybAlgoData();
    }
  }, [refetchKey]);
  if (loading) return <CardLoading />;
  return (
    <>
      {" "}
      <div className="flex items-center justify-between py-[1.25rem]">
        <h3 className="heading_4 flex justify-start">
          KYB Algo Organizations{" "}
        </h3>

        <div
          className="cursor-pointer justify-center"
          onClick={() => fetchKybAlgoData()}
        >
          <img src={svg67} alt="svg67" />
        </div>
        <div></div>
      </div>
      <Collapse
        items={renderPanels()}
        defaultActiveKey={["1"]}
        onChange={(key) => console.log("Expanded panel:", key)}
      />
    </>
  );
};

export default CollapseKYB;
