import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: "Transactions",
    Successful: 50,
    Failed: 30,
    colors: {
      Successful: "#3250FF",  
      Failed: "#F9C80E", 
    },
  },
];

function MpesaPayment() {
  const navigate = useNavigate();
  const { dashboardData, loading } = useSelector((state) => state.fetch);
  const handleTransaction = () => {
    navigate("/mpesa-transactions");
  };

  return (
     <div className="service-card  w-full h-full mt-[1.25rem] p-5">
         <div className="heading_4">Distribution of Mpesa Transactions</div>
         <div className="w-full mt-2">
           <ResponsiveContainer width="100%" height={30}>
             <BarChart
               data={dashboardData?.mpesaTransactions}
               layout="vertical"
               margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
             >
               <XAxis type="number" hide />
               <YAxis type="category" dataKey="name" hide />
               <CartesianGrid strokeDasharray="3 3" />
               <Tooltip />
               {Object.keys(
                 dashboardData?.mpesaTransactions?.[0]?.colors || {}
               )?.map((key) => (
                 <Bar
                   barSize={10}
                   key={key}
                   dataKey={key}
                   stackId="a"
                   fill={dashboardData?.mpesaTransactions?.[0]?.colors[key]}
                 />
               ))}
             </BarChart>
           </ResponsiveContainer>
         </div>
   
         <div
           className="mt-3 flex items-center gap-x-10 cursor-pointer"
           onClick={handleTransaction}
         >
           {Object.keys(dashboardData?.mpesaTransactions?.[0]?.colors || {})?.map(
             (key) => (
               <div key={key} className="flex flex-col">
                 <div className="flex gap-x-4 items-center">
                   <span
                     style={{
                       backgroundColor:
                       dashboardData?.mpesaTransactions?.[0]?.colors[key],
                     }}
                     className="w-[14px] h-[14px]"
                   ></span>
                   <span>{key}</span>
                 </div>
                 <div className="ml-[30px] font-[700] text-[20px] font-dmSans">
                   {dashboardData?.mpesaTransactions?.[0]?.[key]}
                 </div>
               </div>
             )
           )}
         </div>
       </div>
  );
}

export default MpesaPayment;
