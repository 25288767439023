import React, { useEffect } from "react";
import BreadCrumb from "../../layout/BreadCrumb";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardLoading from "../../components/CardLoading";
import chamaImg from "../../assets/img/chamaImg.png";
import { capitalize } from "../../utils";
import moment from "moment";
import { setActiveObj } from "../../features/obj/objSlice";
import NoDataTable from "../../components/NoDataTable";
import EmtyCard from "../../components/EmtyCard";
import { fetchOrganizationWallets } from "../../features/fetch/fetchSlice";
import svg8 from "../../assets/svg/svg8.svg";
import svg9 from "../../assets/svg/svg9.svg";

function Wallets() {
  const { loading, orgWallets } = useSelector((state) => state.fetch);
  const { activeOrganization } = useSelector((state) => state.obj);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHome = () => {
    navigate("/org-wallet");
  };

  const handleActiveWallet = async (item) => {
    await dispatch(setActiveObj(item));
    await navigate("/sub-wallets");
  };

  const breadList = [
    {
      title: (
        <div
          className="flex items-center gap-[.25rem] cursor-pointer"
          onClick={handleHome}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Organizations</span>
        </div>
      ),
    },
    {
      title: capitalize("Organization Wallets"),
    },
  ];
 
  async function fetchOrganizationWalletData() {
    await dispatch(
      fetchOrganizationWallets({
        url: "api/v1/wallet/subWallet",
        walOrgUid:activeOrganization?.orgUid
      })
    );
  }

  useEffect(() => {
    fetchOrganizationWalletData();
  }, []);
 
  if (loading) return <CardLoading />;
  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="white_card justify-between items-center ">
        <BreadCrumb
          breadList={breadList}
          header={`${activeOrganization?.orgName} Wallets`}
          info={`View all wallets within ${activeOrganization?.orgName}`}
        />
      </div>
      <div className="white_card mt-[1.25rem]  flex flex-shrink flex-wrap w-full gap-y-[1rem] gap-x-[1rem] ">
      {orgWallets?.length > 0 ? (
        orgWallets?.map((item) => ( 
          <div
            onClick={() => handleActiveWallet(item)}
            style={{
              border: "1px solid var(--Tile-stroke, #E2E9EE)",
            }}
            className="w-[300px] flex flex-col h-full px-[.625rem] py-[1.06rem] rounded-[1.5rem] min-h-[14rem] cursor-pointer"
          >
            <div className="flex justify-between items-center  w-full"> 
              <img
                src={item?.walType === "DISBURSEMENT"? svg8 :item?.walType === "COLLECTION"? svg9 : null}
                className="w-[3.25rem] h-[3.25rem] rounded-full object-cover"
                alt="Chama icon"
              />
        
              <button className="black-round-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M4.16602 10.5001H15.8327M15.8327 10.5001L9.99935 4.66675M15.8327 10.5001L9.99935 16.3334"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div> 

            <span className="heading_4 mt-[1.88rem]">{item?.walType}</span>
            <span className="chama_card_date ">{item?.walDefaultType}</span>
            
            <span className="chama_card_date mt-[.75rem]">
          Balance  {item?.walCurrency} {item?.walBalAmount}
            </span>
            <span className="chama_card_date mt-[.75rem]">
              Created {moment(item?.walCreatedDate).format("Do MMM YYYY")}
            </span>
          </div>
          
        ))
      ) : (
        <EmtyCard title="You dont have any organization wallets" />
      )}
      </div>
    </div>
  );
}

export default Wallets;
