import React, { useState } from "react"; 
import BreadCrumb from "../../layout/BreadCrumb";
import { Tabs } from "antd";
import { capitalize } from "../../utils";
import { setRefetch1, setRefetchKey1 } from "../../features/global/globalSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrganizationPendingTable from "./OrganizationPendingTable";
import OrganizationApprovedTable from "./OrganizationApprovedTable";

function OrganizationDtb() {
    const dispatch =  useDispatch()
    const navigate = useNavigate()
    const [active, setactive] = useState('1');

    async function refetching(activeKey) {
        setactive(activeKey);
        await dispatch(setRefetch1());
        await dispatch(setRefetchKey1(activeKey));
    }

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className=" ">Pending Approval</span>
        </span>
      ),
      key: "1",
      children: <OrganizationPendingTable/>,
    },
    {
      label: (
        <span className="flex items-center">
          <span className=" ">Approved Organizations</span>
        </span>
      ),
      key: "2",
      children: <OrganizationApprovedTable/>,
    },
  ];
  const breadList = [
    {
        title: (
            <div className="flex items-center gap-[.25rem] cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clipPath="url(#clip0_627_13942)">
                        <path
                            d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                            fill="black"
                            fillOpacity="0.45"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_627_13942">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span onClick={()=>navigate('/home')}>Home</span>
            </div>
        ),
        // href: '/#/organization-list',
    },
    {
        title: capitalize('Organization Details'),
    },
];


  return (
    <div className="flex flex-col w-full h-full overflow-y-scroll">
      <div className="white_card justify-between items-center ">
        <BreadCrumb
          breadList={breadList}
          header={"Organization List"}
          info={`Manage all your organizations here`}
        />
      </div>
      <div className="mt-[1.25rem] white_card w-full min-h-[100vh]">
        <Tabs
          items={tabConfigurations}
          defaultActiveKey="1"
          activeKey={active}
          onChange={refetching}
          className="activity-tab-wrap activity-tab activity-bar"
        />
      </div>
    </div>
  );
}

export default OrganizationDtb;
