/* eslint-disable no-shadow */
import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import { useMediaQuery } from "react-responsive";
import svg6 from "../../assets/svg/svg6.svg";
import svg7 from "../../assets/svg/svg7.svg";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter, cashConverter } from "../../utils";

const RADIAN = Math.PI / 180;
const data = [
  { name: "Filled", value: 63, color: "#5A57E4" },
  { name: "Unfilled", value: 37, color: "#F0F2F3" },
];
const cx = 100;
const cy = 120;
const iR = 80;
const oR = 100;
const value = 63; // The percentage of the pie filled

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle
      key="needle-circle"
      cx={x0}
      cy={y0}
      r={r}
      fill={color}
      stroke="none"
    />,
    <path
      key="needle-path"
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};

const Commission = ({ activeBtn }) => {
  const { dashboardData } = useSelector((state) => state.fetch);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  // Calculate the percentage of the pie based on the value
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const percentage = (value / total) * 100;

  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedValue((prev) => {
        if (prev >= value) {
          clearInterval(interval);
          return value;
        }
        return prev + 1;
      });
    }, 40);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="service-card  w-full h-full mt-[1.25rem] p-5">
      <div className="heading_4">Commission Earned</div>
      <ResponsiveContainer width="100%" height={isSmallScreen ? 400 : 300}>
        <PieChart>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0 * (1 - animatedValue / total)}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(animatedValue, data, cx, cy, iR, oR, "#d0d000")}

          <text
            x={cx}
            y={cy + oR - 70}
            textAnchor="middle"
            fontSize={16}
            fill="#000"
          >
            {`${Math.round((animatedValue / total) * 100)}%`}
          </text>
        </PieChart>
      </ResponsiveContainer>
      <div className="lg:mt-[-130px] mt-[-200px]">
        <p className="flex items-center justify-center w-[300px] text-center">
          You succeed, you earned {cashConverter(dashboardData?.commission)}{" "}
          this {capitalizeFirstLetter(activeBtn)}, its higher than yesterday
        </p>
        <div className="flex items-center gap-x-5 mt-2">
          <div className="flex flex-col gap-y-1">
            <span>Target</span>
            <span className="flex items-center font-[700] text-[20px] font-dmSans">
              Ksh 20k
              <img src={svg7} alt="svg7" />
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span>Revenue</span>
            <span className="flex items-center font-[700] text-[20px] font-dmSans">
              {dashboardData?.revenue}
              <img src={svg6} alt="svg6" />
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span>{capitalizeFirstLetter(activeBtn)}</span>
            <span className="flex items-center font-[700] text-[20px] font-dmSans">
              {dashboardData?.commission}
              <img src={svg6} alt="svg6" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commission;
