import { DatePicker, Dropdown, Form, Input, Table, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CardLoading from "../../../components/CardLoading";
import BreadCrumb from "../../../layout/BreadCrumb";
import Search from "../../../components/Search";
import {
  fetchDashboard,
  fetchTransactions,
  fetchUsers,
} from "../../../features/fetch/fetchSlice";
import MaterialIcon from "material-icons-react";
import { downloadExcel } from "../../../features/global/globalSlice";
import {
  cashConverter,
  dateForHumans,
  disabledDate,
  formatDate,
  formatDateAndTime,
  normalizeDateToLocal,
  timeAmPm,
} from "../../../utils";

const { RangePicker } = DatePicker;
export default function MpesaTransactionsTable() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { transactionData, loading, transactionCount, dashboardData } =
    useSelector((state) => state.fetch);

  const [searchText, setSearchText] = useState("");

  const [open, setopen] = useState(false);

  const [searchInput, setsearchInput] = useState(null);

  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    dispatch(
      fetchTransactions({
        url: "api/v1/payments/fetchPaymentTransactions",
        blkOrgName: e.target.value == "" ? null : e.target.value,
      })
    );
  };

  const columns = [
    {
      title: "Amount",
      dataIndex: "blkPaymentAmount",
      render: (item) => {
        return <div>{cashConverter(item)}</div>;
      },
      sorter: (a, b) => a.blkPaymentAmount - b.blkPaymentAmount,
    },
    {
      title: "Payment Method",
      dataIndex: "blkPaymentType",
      sorter: (a, b) => a.blkPaymentType.localeCompare(b.blkPaymentType),
    },
    {
      title: "Payment Reason",
      dataIndex: "blkPaymentReason",
      sorter: (a, b) => a.blkPaymentReason.localeCompare(b.blkPaymentReason),
    },
    {
      title: "Receiver Mobile Number",
      dataIndex: "blkReceiverMobileNo",
      sorter: (a, b) =>
        a.blkReceiverMobileNo.localeCompare(b.blkReceiverMobileNo),
    },
    {
      title: "Receiver Account Number",
      dataIndex: "blkBeneficiaryAccount",
      sorter: (a, b) =>
        a.blkBeneficiaryAccount.localeCompare - b.blkBeneficiaryAccount,
    },
    {
      title: "Paying Organization",
      dataIndex: "blkOrgName",
      sorter: (a, b) => a.blkOrgName.localeCompare(b.blkOrgName),
    },
    {
      title: "Date",
      dataIndex: "blkCreatedDate",
      render: (item) => {
        return <div>{dateForHumans(item)}</div>;
      },
      sorter: (a, b) => a.blkCreatedDate.localeCompare(b.blkCreatedDate),
    },
    {
      title: "Status",
      dataIndex: "blkRemoteStatus",
      render: (item) => {
        const colors = {
          "Timed out at agent": "#FF9800",
          SUCCESS: "#28A745",
          INVALID: "#3250FF",
          FAILURE: "#F44336",
        };

        return (
          <div
            className="text-[#FFF] inline-block rounded-[5px] px-2 py-1 w-auto"
            style={{
              backgroundColor: colors[item] || "darkGreen",
            }}
          >
            {item}
          </div>
        );
      },
    },
  ];

  const [activeBtn, setActiveBtn] = useState(null);
  const handleClick = async (item) => {
    await setActiveBtn(item);

    await form.resetFields();
    await setStartDate(null);
    await setEndDate(null);

    await dispatch(
      fetchTransactions({
        blkRemoteStatus: item,
        blkPaymentType: "MPESA",
        url: "api/v1/payments/fetchPaymentTransactions",
      })
    );
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDownload = async (item) => {
    const res = await dispatch(
      downloadExcel({
        url: "api/v1/payments/downloadTransactions",
        startDate: normalizeDateToLocal(startDate),
        endDate: normalizeDateToLocal(endDate),
        blkRemoteStatus: activeBtn,
        blkPaymentType: "MPESA",
      })
    );
    if (res?.payload) {
      const blob = new Blob([res.payload], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "transaction-statement.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast.success("File downloaded successfully");
      setStartDate(null);
      setEndDate(null);
    } else {
      toast.error("Failed to download file");
    }
  };

  const onFinish = async (values) => {
    await setStartDate(values?.[0]);
    await setEndDate(values?.[1]);
    await dispatch(
      fetchTransactions({
        url: "api/v1/payments/fetchPaymentTransactions",
        startDate: normalizeDateToLocal(values?.[0]),
        endDate: normalizeDateToLocal(values?.[1]),
        blkPaymentType: "MPESA",
      })
    );
    await setActiveBtn("");
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchTransactionsData(page, size) {
    dispatch(
      fetchTransactions({
        url: "api/v1/payments/fetchPaymentTransactions",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
        blkRemoteStatus: null,
        blkPaymentType: "MPESA",
        startDate: null,
        endDate: null,
        blkOrgUid: null,
      })
    );
  }

  async function fetchDashboardData() {
    await dispatch(
      fetchDashboard({
        url: `api/v2/dashboard/fetch-dashboard`,
        startDate: null,
        endDate: null,
      })
    );
  }

  useEffect(() => {
    fetchTransactionsData();
    fetchDashboardData();
  }, []);
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: <div className="text-lighGold text-[16px]">Mpesa Transactions</div>,
    },
  ];

  const statusKeys = Object?.keys(dashboardData?.mpesaTransactions?.[0])?.filter(
    (key) => key !== "name" && key !== "colors"
  );
  if (loading && !searchInput) return <CardLoading />;
  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Transactions"}
          info={"Here’s an overview of your M-pesa transactions"}
        />
      </div>

      <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
        <div className="flex flex-col w-full lg:px-10 px-3 ">
          <div className="flex items-center mt-[1.25rem] justify-between ">
            <div className="flex justify-start items-center ">
              <h3 className="heading_4 ">Transactions</h3>
              <div className="ml-10 flex flex-srhink flex-wrap items-center lg:gap-y-0 gap-y-3 gap-x-3">
                <button
                  onClick={() => handleClick(null)}
                  className={`${
                    activeBtn === null
                      ? "text-[#146ADC] border-[#146ADC]"
                      : "border-[#ECEFF3]"
                  } border rounded-[10px] flex items-center gap-x-5 py-1 px-3 font-[500]`}
                >
                  <span>All</span>
                  {statusKeys?.reduce(
                    (total, key) =>
                      total + dashboardData?.mpesaTransactions?.[0][key],
                    0
                  )}
                </button>

                {statusKeys?.map((key) => (
                  <button
                    key={key}
                    onClick={() => handleClick(key)}
                    className={`${
                      activeBtn === key
                        ? `text-[#146ADC] border-[#146ADC]`
                        : "border-[#ECEFF3]"
                    } border rounded-[10px] flex items-center gap-x-5 py-1 px-3 font-[500]`}
                  >
                    <span
                      style={{
                        color:
                          activeBtn === key
                            ? dashboardData?.mpesaTransactions?.[0]?.colors[key]
                            : "#000",
                      }}
                    >
                      {key}
                    </span>
                    {dashboardData?.mpesaTransactions?.[0][key]}
                  </button>
                ))}
              </div>
            </div>
            <div className="justify-end flex">
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                className=""
                style={{
                  maxWidth: "100%",
                }}
                form={form}
              >
                <Form.Item
                  name="range"
                  rules={[
                    {
                      required: false,
                      message: "Field is required",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={disabledDate}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
            <div className="w-[16.8125rem]">
              <Search
                searchInput={searchInput}
                handleSearch={handleSearch}
                text={"Search oragnization name..."}
              />
            </div>
            <div className="flex justify-end item-center">
              <Tooltip placement="top" title={"Download Excel"}>
                <button onClick={handleDownload} className="flex items-center ">
                  <MaterialIcon size={45} color="#00B050" icon="article" />
                  <span>Export to excel</span>
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="mb-5">
            <section>
              <Table
                rowSelection={false}
                className="mt-[1.31rem] w-full"
                scroll={{
                  x: 800,
                }}
                rowKey={(record) => record?.blkUid}
                columns={columns}
                dataSource={transactionData}
                loading={loading}
                pagination={{
                  position: ["bottomCenter"],
                  current: pageIndex + 1,
                  total: transactionCount,
                  pageSize: pageSize,
                  onChange: (page, size) => {
                    setPageIndex(page - 1);
                    setPageSize(size);
                    fetchTransactionsData(page - 1, size);
                  },
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
