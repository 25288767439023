import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";
import Commission from "./Commission";
import RevenueGraph from "./RevenueGraph";
import { DatePicker, Form } from "antd";
import OrganizationStatus from "./OrganizationStatus";
import { fetchDashboard } from "../../features/fetch/fetchSlice";
import { useDispatch, useSelector } from "react-redux";
import CardLoading from "../../components/CardLoading";
import {
  disabledDate,
  formatDate,
  getDate30DaysAgo,
  getDate7DaysAgo,
} from "../../utils";
import BankPayment from "./BankPayment";
import MpesaPayment from "./MpesaPayment";

function DashboardList() {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [activeBtn, setActiveBtn] = useState("All");
  const { dashboardData, loading } = useSelector((state) => state.fetch);

  const today = new Date();

  const handleClick = async (item) => {
    await setActiveBtn(item);
    if (item === "WEEK") {
      await form.resetFields();
      await dispatch(
        fetchDashboard({
          startDate: getDate7DaysAgo(),
          endDate: formatDate(today),
          url: `api/v2/dashboard/fetch-dashboard`,
        })
      );
    }
    if (item === "MONTH") {
      await form.resetFields();
      await dispatch(
        fetchDashboard({
          startDate: getDate30DaysAgo(),
          endDate: formatDate(today),
          url: `api/v2/dashboard/fetch-dashboard`,
        })
      );
    }
    if (item === "DAY") {
      await form.resetFields();
      await dispatch(
        fetchDashboard({
          startDate: formatDate(today),
          url: `api/v2/dashboard/fetch-dashboard`,
        })
      );
    }
    if (item === "All") {
      await form.resetFields();
      dispatch(
        fetchDashboard({
          startDate: null,
          endDate: null,
          url: `api/v2/dashboard/fetch-dashboard`,
        })
      );
    }
  };

  const onFinish = async (data) => {
    await setActiveBtn("");
    await dispatch(
      fetchDashboard({
        url: `api/v2/dashboard/fetch-dashboard`,
        startDate: formatDate(data),
        endDate: formatDate(data),
      })
    );
  };

  async function fetchDashboardData() {
    await dispatch(
      fetchDashboard({
        url: `api/v2/dashboard/fetch-dashboard`,
        startDate: null,
        endDate: null,
      })
    );
  }

  useEffect(() => {
    fetchDashboardData();
  }, []);
  if (loading) return <CardLoading />;

  return (
    <div className="bg-[#F6F5FB] w-full h-full overflow-y-scroll lg:p-10 p-3">
      <div className="flex items-center justify-between">
        <div className="flex lg:flex-row flex-col lg:items-center items-start gap-x-14 mb-2  w-auto p-1.5">
          <button
            onClick={() => handleClick("All")}
            className={`${
              activeBtn === "All"
                ? "text-[#146ADC] border border-[#146ADC]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#146ADC]"></span>{" "}
            All
          </button>
          <button
            onClick={() => handleClick("DAY")}
            className={`${
              activeBtn === "DAY"
                ? "text-[#2DD683] border border-[#2DD683]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#2DD683]"></span>{" "}
            DAY
          </button>

          <button
            onClick={() => handleClick("WEEK")}
            className={`${
              activeBtn === "WEEK"
                ? "text-[#FFAB00] border border-[#FFAB00]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#FFAB00]"></span>{" "}
            WEEK
          </button>
          <button
            onClick={() => handleClick("MONTH")}
            className={`${
              activeBtn === "MONTH"
                ? "text-[#9D1500] border border-[#9D1500]"
                : ""
            }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
          >
            <span className="w-[7px] h-[7px] rounded-full bg-[#9D1500]"></span>{" "}
            MONTH
          </button>
        </div>
        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <Form.Item
              name="custNationalIdentityDateOfIssuance"
              rules={[
                {
                  required: false,
                  message: "Field is required",
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate} onChange={onFinish} />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Card />
      <div className=" flex lg:flex-row flex-col w-full gap-5">
        <Commission activeBtn={activeBtn} />
        <OrganizationStatus />
      </div>
      <div className=" flex lg:flex-row flex-col w-full gap-5">
        <BankPayment />
        <MpesaPayment />
      </div>

      <div className="flex mt-[1.25rem] w-full">
        <RevenueGraph />
      </div>
    </div>
  );
}

export default DashboardList;
