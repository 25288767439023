import { createHashRouter } from 'react-router-dom';
import Login from './pages/auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
import Root from './Root'; 
import VerifyEmail from './pages/auth/forgot_pasword/VerifyEmail';
import NewPassword from './pages/auth/forgot_pasword/NewPassword';
import PasswordResetSuccess from './pages/auth/forgot_pasword/PasswordResetSuccess';
import ForgotPassword from './pages/auth/forgot_pasword/ForgotPassword';
import LoginOtpCode from './pages/auth/LoginOtpCode';
import Home from './pages/home/Home';  
import PaybillTable from './pages/setup/paybill/PaybillTable';
import TariffTable from './pages/setup/tariff/TariffTable';
import TariffNegotiatedTable from './pages/setup/tariff_negotiated/TariffNegotiatedTable';
import CommissionsTable from './pages/payments/commissions/CommissionsTable';
import CustomerTable from './pages/customer_approval/CustomerTable'; 
import OrganizationCustomerTable from './pages/customer_approval/OrganizationCustomerTable';
import CustomerDetailsList from './pages/customer_approval/details/CustomerDetailsList';
import OrganizationDocAttachment from './pages/organization_doc_attachment/OrganizationDocAttachment';
import OrganizationApproval from './pages/home/organization_approval/OrganizationApproval';
import OrganizationApprovalDetails from './pages/home/organization_approval/organization-details/OrganizationApprovalDetails';
import OrganizationDocDetails from './pages/organization_doc_attachment/organization-details/OrganizationDocDetails';
import OrganizationWalletTable from './pages/organization_wallets/OrganizationWalletTable'; 
import Wallets from './pages/organization_wallets/Wallets';
import Subwallet from './pages/organization_wallets/Subwallet';
import WalletView from './pages/organization_wallets/WalletView';
import DashboardList from './pages/dashboard/DashboardList';
import UserView from './pages/users/UserView';
import UsersTable from './pages/users/UsersTable';
import BankTransactionsTable from './pages/payments/transactions/BankTransactionsTable';
import OrganizationDtb from './pages/organization_dtb/OrganizationDtb';
import MpesaTransactionsTable from './pages/payments/transactions/MpesaTransactionsTable';
 
export const router = createHashRouter([
	
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '/',
				element: <Login />,
				index: true,
			},
			{
				path: '/login',
				element: <Login />,
			},
			 
			{
				path: '/forgot-password',
				element: <ForgotPassword />,
			},
			{
				path: '/verify-email',
				element: <VerifyEmail />,
			},
			{
				path: '/new-password',
				element: <NewPassword />,
			},
			{
				path: '/login-otp',
				element: <LoginOtpCode />,
			},
			{
				path:'/password-reset-success',
				element: <PasswordResetSuccess />,
			},
			 
			 
			
			  
			{
				element: <ProtectedRoute role={"EXECUTIVE"} />,
				children: [
					
					{
						path: '/home',
						element: <Home />,
					},
 
					 

					{
						path: '/organization-approval-details',
						element: <OrganizationApprovalDetails />,
					},
					{
						path: '/organization-doc-details',
						element: <OrganizationDocDetails />,
					},
					
					
					{
						path: '/org-attachments',
						element: <OrganizationDocAttachment />,
					},
					{
						path: '/org-approval',
						element: <OrganizationApproval />,
					},
					

					{
						path: '/paybill-list',
						element: <PaybillTable />,
					},
					{
						path: '/tariff-list',
						element: <TariffTable />,
					},
					{
						path: '/tariff-negotiated-list',
						element: <TariffNegotiatedTable />,
					},
					{
						path: '/commissions',
						element: <CommissionsTable />,
					},
					{
						path: '/organization-customerApproval',
						element: <OrganizationCustomerTable />,
					},
					 
					{
						path: '/customer-list',
						element: <CustomerTable />,
					},
					{
						path: '/customer-details',
						element: <CustomerDetailsList />,
					},
					{
						path: '/org-wallet',
						element: <OrganizationWalletTable />,
					},
					{
						path: '/wallets',
						element: <Wallets />,
					},
					{
						path: '/sub-wallets',
						element: <Subwallet />,
					},
					{
						path: '/wallet-view',
						element: <WalletView />,
					},
					
					{
						path: '/dashboard',
						element: <DashboardList />,
					},
						
					{
						path: '/users',
						element: <UsersTable />,
					},
					{
						path: '/user-view',
						element: <UserView />,
					},
					{
						path: '/bank-transactions',
						element: <BankTransactionsTable />,
					},
					{
						path: '/mpesa-transactions',
						element: <MpesaTransactionsTable />,
					},
					
					{
						path: '/organization-dtb',
						element: <OrganizationDtb />,
					},
					
					
				 	 
					 
				],
			},
			{
				path: '*',
				element: <p>404 - Error: Page not found</p>,
			},
		],
	},
]);
