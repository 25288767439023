import React, { useEffect, useState, useRef } from "react";
import { Form, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAttachmentSetup } from "../../../features/fetch/fetchSlice";
import { formatPathFile } from "../../../utils";
import toast from "react-hot-toast";
import {
  downloadFiles,
  setRefetchKey,
} from "../../../features/global/globalSlice";
import ClearanceUpload from "../../../components/ClearanceUpload";
import { save } from "../../../features/save/saveSlice";

const url = process.env.REACT_APP_API_BASE_URL;

function KyuDocuments({ setactive }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    attachmentSetupCount,
    attachmentSetup,
    loading,
    organizationAdminData,
  } = useSelector((state) => state.fetch);
  const { saving } = useSelector((state) => state.auth);
  const { refetch, refetchKey } = useSelector((state) => state.global);
  const { activeOrganization } = useSelector((state) => state.obj);

  const [docsArray, setDocsArray] = useState([]);
  const [allUploads, setAllUploads] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (attachmentSetup) {
      const docs = attachmentSetup.map((item) => ({
        id: item?.attId ?? null,
        name: item?.attAttrName,
        type: item?.attAttrType,
        fileUrl: item?.attUrl,
        attKyValue: item?.attKyValue,
        attStatus: item?.attStatus,
        attRemarks: item?.attRemarks,
      }));
      setDocsArray(docs);
    }
  }, [attachmentSetup]);

  const handleSuccess = async (response, id) => {
    const res = await dispatch(
      save({
        url: `api/v2/onboard/update-attachment`,
        attUrl: response?.data?.result?.fileName,
        attId: id,
      })
    );

    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await fetchAttachmentSetupData();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleError = (response) => {
    console.log("response error", response);
    toast.error("Error Uploading file.");
  };

  const columns = [
    {
      title: "Document Title",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Attach File",
      key: "attachedFile",
      render: (item) => (
        <button
          disabled={
            item?.attStatus === "SUBMITTED" || item?.attStatus === "ACCEPTED"
              ? true
              : false
          }
          type="button"
          className={`w-full ${
            item?.attStatus === "SUBMITTED" || item?.attStatus === "ACCEPTED"
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          <ClearanceUpload
            className="att-docs-dragger"
            fileName={item?.value?.split("_").pop()}
            name={item?.name}
            type={item?.type}
            keyId={item?.id}
            url={url}
            onSuccess={(response) => handleSuccess(response, item.id)}
            onError={handleError}
          />
        </button>
      ),
    },
    {
      title: "View File",
      render: (item) => (
        <div
          onClick={item?.fileUrl ? () => handleClick(item) : null}
          className={`cursor-pointer ${
            item?.fileUrl ? "text-[#00008B]" : "text-gray-500"
          }`}
        >
          {item?.fileUrl ? formatPathFile(item.fileUrl) : "No File"}
        </div>
      ),
    },

    {
      title: "Status",
      key: "attStatus",
      render: (item) => {
        return <div>{item == null ? "N/A" : item}</div>;
      },
      dataIndex: "attStatus",
    },
    {
      title: "Remarks",
      key: "attRemarks",
      render: (item) => {
        return <div className="cursor-not-allowed">{item}</div>;
      },
      dataIndex: "attRemarks",
    },
  ];

  const handleClick = async (item) => {
    const res = await dispatch(
      downloadFiles({
        attrType: item?.type,
        fileName: item?.fileUrl,
      })
    );

    if (res?.payload) {
      const blob = new Blob([res.payload], {
        type: res.payload.type || "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", formatPathFile(item?.fileUrl));
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      toast.success("File downloaded successfully");
    } else {
      toast.error("Failed to download file");
    }
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const fetchAttachmentSetupData = async (page, size) => {
    await dispatch(
      fetchAttachmentSetup({
        url: "api/v2/onboard/fetch-attachments",
        attKyKey: "usrId",
        attKyValue: organizationAdminData?.usrId,

        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  };

  const onFinish = async () => {
    const allFilesUploaded = docsArray.every((item) => item?.fileUrl);

    if (allFilesUploaded) {
      const res = await dispatch(
        save({
          usrId: organizationAdminData?.usrId,
          attKyKey: "usrId",
          url: "api/v2/onboard/submit-attachment",
        })
      );
      if (res?.payload?.success) {
        await toast.success(res?.payload?.messages?.message);
        await form.resetFields();
        if (activeOrganization?.orgBizTypeName === "INDIVIDUAL") {
          await navigate("/org-approval");
        } else {
          await dispatch(setRefetchKey("3"));
          await setactive("3");
        }
      } else {
        toast.error(res?.payload?.messages?.message);
      }
    } else {
      toast.error("Upload all documents to submit for approval");
    }
  };

  useEffect(() => {
    if (refetchKey == 2) {
      fetchAttachmentSetupData();
    }
  }, [refetchKey]);

  useEffect(() => {
    fetchAttachmentSetupData();
  }, []);

  useEffect(() => {
    const allUploaded = docsArray.every((item) => item?.fileUrl);
    setAllUploads(allUploaded);
  }, [docsArray]);

  return (
    <div className="flex w-full min-h-screen bg-white ">
      <div className="flex flex-col w-full">
        <div className="">
          <div className="outer-card-content pb-10 mt-[1rem]">
            <div className="flex flex-col h-auto w-full items-center justify-center  mt-[2.31rem]">
              <span className="heading_1">
                {activeOrganization?.orgBizTypeName === "INDIVIDUAL"
                  ? "KYC Documents"
                  : "KYU Documents"}
              </span>
              <span className="paragraph_1 mt-[1.13rem]">
                Attach the following{" "}
                {activeOrganization?.orgBizTypeName === "INDIVIDUAL"
                  ? "KYC"
                  : "KYU"}{" "}
                documents for the admin
              </span>
            </div>
            <div className="">
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{ maxWidth: "100%" }}
                form={form}
              >
                <Table
                  bordered
                  rowSelection={false}
                  className="mt-[1.31rem] w-full"
                  scroll={{ x: 800 }}
                  rowKey={(record) => record?.id}
                  columns={columns}
                  dataSource={docsArray}
                  loading={loading}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: attachmentSetupCount,
                    pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchAttachmentSetupData(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                />
                <div className="mt-10 flex flex-col items-center justify-center">
                  <div className="">
                    <button className="cstm-btn-2 w-[10.625rem]" type="submit">
                      {saving ? <Spin /> : " Confirm Submission"}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KyuDocuments;
